import React from 'react'
import TextField from '@mui/material/TextField'

export default function YearlyCommissionEstimate(props) {
  const { handleEstimatedInstalls, estimatedInstalls, selectedPayScale } = props

  return(
    <div className="width100">
      <h2>Yearly Commission Estimate</h2>
      <p>Estimated # of Installs Per Year</p>
      <div className='flex-column width100'>
        <div className='flex-row width100 flex-ai-fs'>
          <TextField
            id="outlined-number"
            type="number"
            onChange={e => handleEstimatedInstalls(e.target.value)}
            value={estimatedInstalls}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
            }}
            InputProps={{}}
            sx={{width: '100%'}}
          />
        </div>
        <div className='margin-top border-gray width100 flex-column'>
          <div className='bg-gray flex-row flex-jc-center padding5'>
            <h3 className="margin0">Commission Estimate</h3>
          </div>
          <div className='padding15'>
            <div className='flex-row width100 flex-jc-sb flex-ai-center'>
              <p># of Installs Per Year</p>
              <p className='margin0 bold'>{estimatedInstalls}</p>
            </div>
            <div className='flex-row width100 flex-jc-sb flex-ai-center'>
              <p>Commission Per Install</p>
              <p className='margin0 bold'>${Math.round(selectedPayScale?.totalCommission) || 0}</p>
            </div>
            <div className='flex-row width100 flex-jc-sb flex-ai-center'>
              <p>Annual Earnings Estimate</p>
              <p className='margin0 bold'>${Math.round(estimatedInstalls * selectedPayScale?.totalCommission) || 0}</p>
              </div>
            </div>
        </div>
        <p>**Intended for general information purposes only. Actual commissions paid may be different than values shown in this calculator.</p>
      </div>
    </div>
  )
}
