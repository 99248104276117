import React, { useEffect } from 'react'
import {
  Tooltip,
  Box,
  FormControl,
  MenuItem,
  Button
} from '@mui/material'
import Select from "../Select"
import AdditionalSettings from '../LoanAndCash/AdditionalSettings'
import PayScale from './PayScale'
import SystemSize from '../SystemSize'

function SunRun(props) {
  const {
    handleInstaller,
    GSFormValues,
    installer,
    market,
    product,
    escalator,
    handleEscalator,
    handleEPCValues,
    EPCOptions,
    setEPCOptions,
    EPCValues,
    setGSFormValues,
    setSunRunMarketValues,
    setInstaller,
    systemSizeKW,
    handleSystemSizeKW,
    netPricePerWatt,
    lead,
    deductions,
    installerBaseline,
    netPricePerWattValues,
    tcrVariables,
    handleDeductions,
    handleLead,
    buildSunRunTableCalculations,
    setMarketEscalatorValues,
    marketEscalatorValues,
    sunRunCalculationsToDisplay,
    repTenure,
    setContractSignDate,
    contractSignDate,
    epcRedline
  } = props

  useEffect(() => {
    if(EPCValues.length === 0) {
      fetch(`/api/sun_run_values`)
      .then(res => res.json())
      .then(json => {
        handleEPCValues(json)
        let data = json.map(x => x[0])
        let values = [...new Set(data)]

        setGSFormValues({marketValues: values, installerValues: ['Freedom Forever']})
        setSunRunMarketValues(values)
        setInstaller('Freedom Forever')

        if(market && json) {
          let marketEscVals = json.filter(x => x[0] === market)
          let epcOptions = marketEscVals.map(x => x[1])
          let data = [...new Set(epcOptions)]

          setMarketEscalatorValues(marketEscVals)
          setEPCOptions(data)
        }
      }) 
    }

  },[])

  return (
    <div className='width100 flex-column flex-ai-fs flex-jc-fs'>
      <div className='flex-column width100'>
        <Tooltip title="This is the annual cost escalator for the PPA product being sold">
          <p>Escalator</p>
        </Tooltip>
        <div className='flex-row width100 flex-ai-fs'>
          <Box sx={{ minWidth: '100%' }}>
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={escalator}
                onChange={e => handleEscalator(e.target.value)}
                className="width100"
              >
                {EPCOptions?.map((mv,i) => { return(
                    <MenuItem key={mv + i} value={mv}>{mv}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <div className='flex-column width100'>
        <p>Installer</p>
        <div className='flex-row width100 flex-ai-fs'>
          <Box sx={{ minWidth: '100%' }}>
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={installer}
                onChange={e => handleInstaller(market, e.target.value, product, contractSignDate)}
                className="width100"
              >
                { GSFormValues.installerValues?.map((mv,i) => {
                  return(
                    <MenuItem key={mv + i} value={mv}>{mv}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <SystemSize
        handleSystemSizeKW={handleSystemSizeKW}
        market={market}
        installer={installer}
        product={product}
        systemSizeKW={systemSizeKW}
        netPricePerWatt={netPricePerWatt}
        lead={lead}
        deductions={deductions}
        contractSignDate={contractSignDate}
        setContractSignDate={setContractSignDate}
        installerBaseline={installerBaseline}
        netPricePerWattValues={netPricePerWattValues}
        tcrVariables={tcrVariables}
      />

      <AdditionalSettings 
        handleLead={handleLead}
        handleDeductions={handleDeductions}
        lead={lead}
        deductions={deductions}
        epcRedline={epcRedline}
      />

      <Button
        variant="contained"
        onClick={() => buildSunRunTableCalculations(
          {market, installer, repTenure, product, systemSizeKW, lead, deductions, contractSignDate, installerBaseline, epcRedline},
          tcrVariables,
          systemSizeKW,
          marketEscalatorValues,
          escalator
        )}
        className="width100 margin-top"
        size='large'
      >Show Pay Scale</Button>
      
      <PayScale
        marketEscalatorValues={marketEscalatorValues} 
        sunRunCalculationsToDisplay={sunRunCalculationsToDisplay}
        escalator={escalator}
        epcRedline={epcRedline}
      />
    </div>
  );
}

export default SunRun;
