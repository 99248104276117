import React, { useEffect } from 'react'
import { Button }from '@mui/material'

export default function RepTenure(props){
  const { repTenureValues, 
          repTenure,
          handleRepTenure,
          userType,
        } = props

  return(
    <div className='flex-column width70'>
      {userType && ["specialist", "consultant", "energy_pro"].includes(userType) || repTenureValues.length == 0 ?
        null :
      <div>
        <p>Rep Tenure</p>
        <div className='flex-row width100 flex-ai-fs'>
          {repTenureValues.map((rp,i) => {
            return(
              <Button
                key={rp.title + rp.i + rp.value + i}
                className='width30 margin-right10' variant={rp.value == repTenure ? "contained" : "outlined"}  onClick={() => handleRepTenure(rp.value)}>{rp.title}</Button>
            )
          })}
        </div>
      </div>
      }
    </div>
  )
}