import React from 'react'
import Slider from '@mui/material/Slider';
import { TextField, InputAdornment } from '@mui/material';
import AdditionalSettings from './AdditionalSettings'
import Box from '@mui/material/Box';
import SystemSize from '../SystemSize';

export default function SystemAttributes(props){
  const {
    netPricePerWatt,
    handleNetPricePerWatt,
    systemSizeKW,
    handleSystemSizeKW,
    handleDeductions,
    handleLead,
    lead,
    deductions,
    installer,
    market,
    product,
    installerBaseline,
    netPricePerWattValues,
    tcrVariables,
    contractSignDate,
    setContractSignDate
  } = props

  return(
    <div className='width100 flex-column flex-ai-fs flex-jc-fs'>
      <h3>System Attributes</h3>
      <div className='flex-column width100'>
        <div className="flex-row width100 flex-jc-sb flex-ai-center">
          <p>Net Price per Watt</p>
          <div id="nppw-input-div">
            <TextField
              id="nppw-input"
              type="number"
              onChange={e => handleNetPricePerWatt(Number(e.target.value))}
              value={netPricePerWatt}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 0.01,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"><p className="color-white">$</p></InputAdornment>
              }}
              sx={{width: '100%'}}
            />
          </div>
        </div>
        <div className='flex-row width100 flex-ai-fs'>
          <Box width={'100%'}>
            <Slider
              onChange={e => handleNetPricePerWatt(e.target.value)}
              step={0.01}
              defaultValue={3.6}
              value={netPricePerWatt}
              min={1.80}
              max={5.75}
              aria-label="Default"
              valueLabelDisplay="off" />
          </Box>
        </div>
      </div>

      <SystemSize
        handleSystemSizeKW={handleSystemSizeKW}
        market={market}
        installer={installer}
        product={product}
        systemSizeKW={systemSizeKW}
        netPricePerWatt={netPricePerWatt}
        lead={lead}
        deductions={deductions}
        contractSignDate={contractSignDate}
        setContractSignDate={setContractSignDate}
        installerBaseline={installerBaseline}
        netPricePerWattValues={netPricePerWattValues}
        tcrVariables={tcrVariables}
      />

      <AdditionalSettings
        handleLead={handleLead}
        handleDeductions={handleDeductions}
        lead={lead}
        deductions={deductions}
      />

    </div>
  )
}
