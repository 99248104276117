import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  // hide last border
  '&:nth-of-type(6) td, &:nth-of-type(6) th': {
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    fontSize: 17,
    color: "white",
    backgroundColor: "#666"
  },
}));

function createData(nppw, commissionPerKW, totalCommission) {
  return { nppw, commissionPerKW, totalCommission };
}


export default function PayScale(props) {

  const rows = props.paymentCalculationsToDisplay.map((pc, i) => {
    return createData(pc.nppw, pc.commissionPerKW, pc.totalCommission)
  })

  if (rows.length === 0) {
    return null;
  }

  return(
    <div className='width100 flex-column flex-ai-fs flex-jc-fs'>
    <TableContainer className="margin-top" component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">Net Price</StyledTableCell>
              <StyledTableCell align="right">Commission per kW</StyledTableCell>
              <StyledTableCell align="right">Total Commission</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={row.nppw + i + row.totalCommission}>
                <StyledTableCell component="th" scope="row">
                  {row.nppw}
                </StyledTableCell>
                <StyledTableCell align="right">${row.commissionPerKW || 0}</StyledTableCell>
                <StyledTableCell align="right">${Math.round(row.totalCommission) || 0}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p>**Intended for general information purposes only. Actual commissions paid may be different than values shown in this calculator.</p>
      <p>*"N/A" means that the system does not pay a commission and should not be sold at that price or with that installer.</p>
    </div>
  )
}