import React from 'react'
import {
  TextField,
  InputAdornment,
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function SystemSize(props){
  const {
    handleSystemSizeKW,
    systemSizeKW,
    contractSignDate,
    setContractSignDate
  } = props

  return(
    <div className='flex-column width100'>
      <p>System size (kW)</p>
      <div className='flex-row width100 flex-ai-fs'>
        <TextField
          id="outlined-number-ss"
          type="number"
          onChange={e => handleSystemSizeKW(e.target.value)}
          value={systemSizeKW}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 0.001,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end"><p className="color-white">kW</p></InputAdornment>
          }}
          sx={{width: '100%'}}
        />
      </div>
      <p>Contract Sign Date</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          value={contractSignDate}
          onChange={(e) => {
            setContractSignDate(`${e.$M + 1}/${e.$D}/${e.$y}`);
          }}
          renderInput={(params) => <TextField {...params} />}
          style={{width: '100%'}}
        />
      </LocalizationProvider>
    </div>
  )
}