import React, { useState } from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

export default function AdditionalSettings(props){
  const [containerOpen, setContainerOpen] = useState(false)

  const {
    handleDeductions,
    handleLead,
    lead,
    deductions
  } = props

  return(
    <div className='border-box border-round width100 border-white margin-top padding15'>
      <div onClick={() => setContainerOpen(!containerOpen)} className='flex-row flex-jc-sb flex-ai-center'>
        <h3 className="margin0">Additional Settings</h3>
        {containerOpen ? <RemoveIcon /> : <AddIcon />}
      </div>
      { containerOpen ?
        <div className='flex-column width100'>
          <div className='flex-column width50'>
            <p>Lead</p>
            <div className='flex-row width100 flex-ai-fs'>
              <Button className='margin-right10' variant={lead ? "contained" : "outlined"}  onClick={() => handleLead(true)}>Yes</Button>
              <Button className='margin-right10' variant={!lead ? "contained" : "outlined"} onClick={() => handleLead(false)}>No</Button>
            </div>
          </div>

          <div className='flex-column width100'>
          <Tooltip title="Theses are Costs that directly reduce Total Commissionsable Revenue (like a customer incentive). Do not include adders here, as those costs are shared with the company.">
            <p>Deductions</p>
          </Tooltip>
          <TextField
              id="outlined-number"
              type="number"
              onChange={e => handleDeductions(e.target.value)}
              value={deductions}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                // step: 0.001,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              sx={{width: '100%'}}
            />
          </div>
        </div>
      : null }
    </div>
  )
}
