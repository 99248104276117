import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '../Select';
import Box from '@mui/material/Box';
import SystemAttributes from './SystemAttributes';
import PayScale from './PayScale';
import Button from '@mui/material/Button'
import YearlyCommissionEstimate from './YearlyCommissionEstimate';

function LoanAndCash(props) {
  const {
    installer,
    GSFormValues,
    handleInstaller,
    netPricePerWatt,
    handleNetPricePerWatt,
    systemSizeKW,
    handleSystemSizeKW,
    handleDeductions,
    handleLead,
    lead,
    deductions,
    market,
    repTenureValues,
    repTenure,
    product,
    installDate,
    installerBaseline,
    netPricePerWattValues,
    tcrVariables,
    paymentCalculationsToDisplay,
    buildTablePaymentCalculations,
    estimatedInstalls,
    handleEstimatedInstalls,
    selectedPayScale,
    setContractSignDate,
    contractSignDate,
    installerValues,
    epcRedline
  } = props 

  return (
    <div className='width100 flex-column flex-ai-fs flex-jc-fs'>
      <div className='flex-column width100'>
        <p>Installer</p>
        <div className='flex-row width100 flex-ai-fs'>
          <Box sx={{ minWidth: '100%' }}>
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={installer}
                onChange={e => handleInstaller(market, e.target.value, product, contractSignDate)}
                className="width100"
              >
                {installerValues?.map((mv,i) => {
                  return(
                    <MenuItem key={mv + i} value={mv}>{mv}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <SystemAttributes
        netPricePerWatt={netPricePerWatt}
        handleNetPricePerWatt={handleNetPricePerWatt}
        systemSizeKW={systemSizeKW}
        handleSystemSizeKW={handleSystemSizeKW}
        handleLead={handleLead}
        handleDeductions={handleDeductions}
        lead={lead}
        deductions={deductions}
        installer={installer}
        handleInstaller={handleInstaller}
        GSFormValues={GSFormValues}
        market={market}
        repTenureValues={repTenureValues}
        product={product}
        installDate={installDate}
        installerBaseline={installerBaseline}
        netPricePerWattValues={netPricePerWattValues}
        tcrVariables={tcrVariables}
        setContractSignDate={setContractSignDate}
        contractSignDate={contractSignDate}
      />

      <Button
        variant="contained"
        onClick={() => buildTablePaymentCalculations(
          {market, repTenure, product, systemSizeKW, netPricePerWatt, lead, deductions, contractSignDate, installerBaseline, installer, epcRedline, contractSignDate},
          netPricePerWattValues,
          tcrVariables,
          systemSizeKW
        )}
        className="width100 margin-top"
        size='large'
      >Show Pay Scale</Button>

      <PayScale 
        paymentCalculationsToDisplay={paymentCalculationsToDisplay}
      />

      <YearlyCommissionEstimate
        estimatedInstalls={estimatedInstalls}
        handleEstimatedInstalls={handleEstimatedInstalls}
        selectedPayScale={selectedPayScale}
      />
    </div>
  );
}

export default LoanAndCash;
