import React, { useEffect } from 'react'
import { Button }from '@mui/material'
import {
  TextField,
  InputAdornment,
} from '@mui/material'

export default function AdminPortal(props){
  const { adminRepTenureValues, setAdminRepTenureValues, leadershipLevel, handleLeadershipLevel, setAdminPassword, adminPassword, createNewButtonVariables } = props

  const buildRepTenureAdminSelections = (button) => {
    let array = adminRepTenureValues
    array.forEach((x, index) => {
      if(x.title == button.title) {
        x.selected = !x.selected
      } else {
        return
      }
    })

    console.log(array)
    setAdminRepTenureValues(state => {
      return [...state]
    })
  }


  const buildLeadershipLevelArray = (title, leadershipLevel) => {
    let array = leadershipLevel
    array.forEach((x, index) => {
      if(x.title == title) {
        x.selected = !x.selected
      } else {
        return
      }
    })

    handleLeadershipLevel(state => {
      return [...state]
    })
  }

  return(
    <div className='flex-column width100'>
      <div>
        <p>Rep Tenure</p>
        <div className='flex-row width100 flex-ai-fs'>
          {adminRepTenureValues.map((rp,i) => {
            return(
              <Button
                key={rp.title + rp.i + rp.value + i}
                className='width30 margin-right10'
                variant={rp.selected ? "contained" : "outlined"}
                onClick={() => buildRepTenureAdminSelections(rp)}>{rp.title}</Button>
            )
          })}
        </div>
      </div>

      <div className='flex-column width100'>
        {leadershipLevel?.length > 0 ? <p>Leadership Level</p> : null }
          <div className='width100 flex-ai-fs flex-row-wrap'>
            {leadershipLevel?.map((option,i) => {
              return(
                <div key={option.title + option.value + i}
                  style={{margin: '5px 5px 5px 0'}}>
                  <Button sx={{fontSize: '0.75rem', width: '140px'}} 
                    variant={option.selected ? "contained" : "outlined"}
                    onClick={() => buildLeadershipLevelArray(option.title, leadershipLevel)}
                  > 
                    {option.title}
                  </Button>
                </div>
              )
            })}
          </div>

          <div className='flex-column width100'>
            <p>Password</p>
            <div className='flex-row width90 flex-ai-fs'>
              <TextField
                id="outlined-number"
                type="text"
                onChange={e => setAdminPassword(e.target.value)}
                value={adminPassword}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{width: '100%'}}
              />
            </div>
          </div>

          <Button
            variant="contained"
            onClick={() => createNewButtonVariables(adminPassword, adminRepTenureValues, leadershipLevel)}
            className="width100 margin-top"
            size='large'
          >Create New Custom Password</Button>
      </div>
    </div>
  )
}