import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function PayScale(props) {
  const [perInstall, setPerInstall] = useState(true)

  let headers = props?.marketEscalatorValues?.filter((x,i) => x[2] == 'ppkwh' && x[1] == props.escalator)[0]?.filter((x,i) => i >= 3 && i <= 10).map(x => Math.trunc(x.replace(',', '')))
  const indexToRemove = props?.marketEscalatorValues?.filter((x,i) => x[2] == 'ppkwh' && x[1] == props.escalator).map((x,i) => {
    if(x[2] == 'ppkwh' && x[1] == props.escalator) {
      return i
    } else {
      return null
    }
  }).filter(x => x != null)[0]
  
  headers?.splice(0, 0, ' ')

  const yAxis = props?.marketEscalatorValues?.filter((x,i) => x[1] == props.escalator && x[2] !== 'ppkwh').map(x => x[2])

  const rows = props?.sunRunCalculationsToDisplay?.filter((x,i) => i != indexToRemove)
  const newRows = rows.map((x,i) => {
    let y = yAxis.filter((x,index) => index == i)[0]

    return [y, ...x]
  })

  if (rows.length === 0) { 
    return null;
  }

  console.log(rows.length)
  console.log(rows)
  return(
    <div style={{marginTop: '25px'}} className='width100 flex-column flex-ai-fs flex-jc-fs'>
    <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
      <p style={{color: 'white', marginRight: '10px'}}>Per Kw</p>
      <FormControlLabel
        style={{color: 'white'}}
        control={<Switch defaultChecked onClick={() => setPerInstall(!perInstall)}/>}
        label="Per Install"
      />
    </FormGroup>
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
                  <StyledTableCell align="center" colSpan={newRows[0].length}>Sun Hours</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              { headers?.map((h,i) => {
                return(
                  <StyledTableCell key={"h" + i} align="right">{h}</StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {newRows.map((row, i) => (
              <StyledTableRow key={"row" + i}>
                {row.filter((x,i) => i == 0).map(cell => {
                  return (
                    <StyledTableCell style={{backgroundColor: 'black', color: 'white', border: 'black'}} key={"cell" + i + cell} component="th" scope="row">
                      <strong>{cell}</strong>
                    </StyledTableCell>
                  )
                })}
                {row.filter((x,i) => i != 0).map((cell, i) => {
                  return(
                    <StyledTableCell key={"cell" + i + cell.totalCommission} component="th" scope="row">
                      ${perInstall ? Math.round(cell.totalCommission) : Math.round(cell.commissionPerKW)}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p>**Intended for general information purposes only. Actual commissions paid may be different than values shown in this calculator.</p>
      <p>*"N/A" means that the system does not pay a commission and should not be sold at that price or with that installer.</p>
    </div>
  )
}