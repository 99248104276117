import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'

export default function LeaderShipLevel(props) {
  const {
    leadershipLevel,
    handleLeadershipLevel,
  } = props

  const buildLeadershipLevelArray = (title, leadershipLevel) => {
    let selected = leadershipLevel.filter(x => x.selected) 
    let selectedCount = selected.length
    let deselecting = selected.map(x => x.title).includes(title)    

    if(deselecting && selectedCount <= 1) {
      return
    } else {
      let array = leadershipLevel
      array.forEach((x, index) => {
        if(x.title == title) {
          x.selected = !x.selected
        } else {
          return
        }
      })

      handleLeadershipLevel(state => {
        return [...state]
      })
    }
  }

  return(
    <div className='width100 flex-column flex-ai-fs flex-jc-fs'>
      <div className='flex-column width100'>
        {leadershipLevel?.length > 0 ? <p>Leadership Level</p> : null }
          <div className='width100 flex-ai-fs flex-row-wrap'>
            {leadershipLevel?.map((option,i) => {
              return(
                <div key={option.title + option.value + i}
                  style={{margin: '5px 5px 5px 0'}}>
                  <Button sx={{fontSize: '0.75rem', width: '140px'}} 
                    variant={option.selected ? "contained" : "outlined"}
                    onClick={() => buildLeadershipLevelArray(option.title, leadershipLevel)}
                  > 
                    {option.title}
                  </Button>
                </div>
              )
            })}
          </div>
      </div>
    </div>
  )
}